export const useSeo = () => {
  const { currentWebsite } = useWebsite()

  const brandNameMapping: { [key: string]: string } = {
    'giorgio-armani': 'Giorgio Armani',
    ea7: 'EA7',
    'emporio-armani': 'Emporio Armani',
    'armani-exchange': 'Armani Exchange',
    'armani-casa': 'Armani Casa',
  }

  const currentBrandName = computed(
    () => brandNameMapping[currentWebsite.value] || 'Armani'
  )

  const { ts } = useI18n()

  /**
   * Generates SEO metadata for editorial pages
   *
   * @param {EditorialMeta} extraData - Extra data for Editorial Pages
   *
   * @returns {object} SEO metadata with title and description
   */
  const getEditorialMeta = ({ title = '', brand = '' }: EditorialMeta) => ({
    title: `${title} | ${brand}`,
    description: ts('metaDescription.editorial', {
      title: title,
      brand: brand,
    }),
  })

  /**
   * Generates SEO metadata for PLP
   *
   * @param {PlpMeta} extraData - Extra data for PLP
   *
   * @returns {object} SEO metadata with title and description
   */
  const getPlpMeta = ({ title = '' }: PlpMeta) => ({
    title: `${title} | ${currentBrandName.value}`,
    description: ts(`metaDescription.plp.${currentWebsite.value}`, {
      title: title,
    }),
  })

  /**
   * Generates SEO metadata for PDP
   *
   * @param {PdpMeta} extraData - Extra data for PDP
   *
   * @returns {object} SEO metadata with title and description
   */
  const getPdpMeta = ({
    product = '',
    collectionName = '',
    gender = '',
  }: PdpMeta) => {
    const i18nDescriptionKey =
      !gender || gender.toLowerCase().includes('unisex')
        ? 'metaDescription.pdpUnisex'
        : 'metaDescription.pdp'

    return {
      title: `${product} | ${currentBrandName.value}`,
      description: ts(i18nDescriptionKey, {
        brand: currentBrandName.value,
        product: product,
        collectionName: collectionName,
        gender: gender.toLowerCase(),
      }),
    }
  }

  /**
   * Generates SEO metadata for brand homepage
   *
   * @param {BrandHomepageMeta} extraData - Extra data for brand homepage
   *
   * @returns {object} SEO metadata with title and description
   */
  const getBrandHomepageMeta = ({
    slug = '',
    brand = '',
  }: BrandHomepageMeta) => {
    const title = convertSlugToTitle(slug)
    return {
      title: `${title} | ${brand}`,
      description: title,
    }
  }

  /**
   * Retrieves SEO metadata based on page type
   *
   * @param {ExtraData} extraData - Object containing extra data including the type of the current page
   *
   * @returns {object} SEO metadata with title and description
   */
  const getMetadata = (extraData: ExtraData) => {
    switch (extraData.type) {
      case 'plp':
        return getPlpMeta(extraData)
      case 'pdp':
        return getPdpMeta(extraData)
      case 'editorial':
        return getEditorialMeta(extraData)
      case 'brandHomepage':
        return getBrandHomepageMeta(extraData)
    }
  }

  return { getMetadata }
}
